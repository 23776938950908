import styled from 'styled-components';
import { rgba } from 'polished';
import AnchorLink from 'react-anchor-link-smooth-scroll';

export const Button = styled(AnchorLink)`
  font-weight: ${props => props.theme.font_weight.medium};
  font-size: ${props => props.theme.font_size.small};
  color: ${props =>
    props.ghost === 'true' ? props.theme.color.black : props.theme.color.white};
  /* letter-spacing: 1px; */
  /* height: 51px; */
  line-height: 51px;
  display: inline-block;
  margin-left: 0px;
  cursor: pointer;
  white-space: nowrap;
  background-color: ${props =>
    props.color ? props.theme.color[props.color] : props.theme.color.black};
  background-color: ${props => props.ghost === 'true' && 'transparent'};
  border-color: ${props =>
    props.color ? props.theme.color[props.color] : props.theme.color.black};
  border-style: solid;
  border-width: 2px;
  border-radius: 2px;
  padding: 0 30px;
  outline: 0px;
  text-decoration: none;
  text-align: center;
  ${props => props.theme.transition};
  box-shadow: ${props =>
    props.hasshadow === 'true'
      ? `0px 10px 20px ${rgba(
          props.color
            ? props.theme.color[props.color]
            : props.theme.color.black,
          0.2
        )}`
      : ''};

  &:hover {
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
    background-color: ${props => props.theme.color.secondary};
    background-color: ${props =>
      props.color && props.ghost && props.theme.color[props.color]};
    background-color: ${props =>
      props.color === 'secondary' && props.theme.color.black};
    border-color: ${props => props.theme.color.secondary};
    border-color: ${props =>
      props.color && props.ghost && props.theme.color[props.color]};
    border-color: ${props =>
      props.color === 'secondary' && props.theme.color.black};
    color: ${props => props.theme.color.white};
  }

  & + & {
    margin-left: 15px;
  }
`;
