export function formatNumber(num) {
  const num1 = num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  return num1.replace(/\.00$/, '');
}

export const addressFormat = (address, nickname) => {
  let returnAddress;

  if (
    address &&
    nickname &&
    address.replace(/\s/g, '') !== nickname.replace(/\s/g, '')
  ) {
    // returnAddress = `${address} (${nickname})`;
    returnAddress = nickname;
  } else if (!address && nickname) {
    returnAddress = nickname;
  } else if (address) {
    returnAddress = address;
  } else {
    returnAddress = 'N/A';
  }

  return returnAddress;
};

export const address2Format = (city, state, zip) =>
  `${city || ''} ${state ? `${state}. ` : ''} ${zip || ''}`;

export const priceFormat = (price, forRent = true, dropPerMonth = false) => {
  let returnPrice;

  if (price) {
    if (forRent !== true) {
      returnPrice = `$${formatNumber(price)}`;
    } else if (dropPerMonth !== false) {
      returnPrice = `$${formatNumber(price)}`;
    } else {
      returnPrice = `$${formatNumber(price)} / month`;
    }
  } else {
    returnPrice = 'Pending';
  }

  return returnPrice;
};

const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const detailsArray = [
  'air_conditioner',
  'allow_animals',
  'allow_smokers',
  'deposit',
  'fiftyfive_plus',
  'garage_carport',
  'lease',
  'leaseterms',
  'masterunit',
  'multiplex',
  'property_id',
  'rental_assistance',
  'type',
  'washer_dryer',
  'washer_dryer_hookups',
  'wheelchair_access',
  'yearbuilt',
];

export const formatDetail = (string) => capitalize(string.replace(/_/gi, ' '));

export const formatForGeocoding = (string) => string.replace(/ /gi, '+');

export const httpToHttps = (url) => url.replace(/http:/gi, 'https:');
