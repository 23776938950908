import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import { Container, ColumnsGrid, Brand } from '@components/global';
import { ReactComponent as EqualHousing } from '@static/icons/equal-housing-opportunity.svg';
import ExternalLink from '../common/ExternalLink';
import BlockContent from '../common/BlockContent';

const Footer = () => {
  const today = new Date();
  const year = today.getFullYear();

  return (
    <StaticQuery
      query={graphql`
        query {
          sanitySitewideSettings {
            _rawFooterText
          }
        }
      `}
      render={data => (
        <>
          <FooterWrapper>
            <hr />
            {data.sanitySitewideSettings._rawFooterText && (
              <FooterCTA>
                <BlockContent
                  data={data.sanitySitewideSettings._rawFooterText}
                />
              </FooterCTA>
            )}
            <div>
              <FooterBrand as="a" href="/">
                Shahid Properties
              </FooterBrand>
              <Copyright>Shahid Properties © {year}</Copyright>
              <EqualHousingImg className="equalHousing" />
              <p className="extraSmall">
                Icons from{' '}
                <a
                  href="https://fontawesome.com/license"
                  rel="noreferrer noopener"
                  target="_blank"
                >
                  FontAwesome
                </a>
              </p>
            </div>
          </FooterWrapper>
        </>
      )}
    />
  );
};

const FooterWrapper = styled(Container.withComponent('footer'))`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 50px;
  flex-direction: column;

  @media (min-width: ${props => props.theme.screen.md}) {
    flex-direction: row;
  }

  hr {
    width: 100%;
    height: 10px;
    background-color: ${props => props.theme.color.black};
    border: 0;
    margin: 0 0 50px;
  }

  ul {
    padding: 0;
    margin: 0;

    li {
      list-style: none;

      a {
      }
    }
  }
`;

const FooterBrand = styled(Brand)`
  font-size: 22px !important;
`;

const Copyright = styled.div`
  font-family: ${props => props.theme.font.secondary};
  ${props => props.theme.font_size.xsmall};
  color: ${props => props.theme.color.font.regular};

  a {
    text-decoration: none;
    color: inherit;
  }
`;

const FooterCTA = styled.div`
  font-size: 39px;
  font-family: ${props => props.theme.font.primary};
  color: ${props => props.theme.color.black};
  width: 100%;
  margin-bottom: 20px;

  @media (min-width: ${props => props.theme.screen.md}) {
    order: 2;
  }

  p {
    ${props => props.theme.font_size.regular};
    font-family: ${props => props.theme.font.primary};
    color: ${props => props.theme.color.black};
    /* line-height: 50px; */

    @media (min-width: ${props => props.theme.screen.sm}) {
      ${props => props.theme.font_size.large};
    }
    @media (min-width: ${props => props.theme.screen.md}) {
      ${props => props.theme.font_size.larger};
    }
    @media (min-width: ${props => props.theme.screen.lg}) {
      ${props => props.theme.font_size.larger};
    }
  }

  @media (min-width: ${props => props.theme.screen.md}) {
    width: 50%;
  }

  a {
    color: ${props => props.theme.color.secondary};
    font-weight: ${props => props.theme.font_weight.bold};

    &:hover {
      color: ${props => props.theme.color.primary};
    }
  }
`;

const EqualHousingImg = styled(EqualHousing)`
  margin-top: 20px;
`;

export default Footer;
