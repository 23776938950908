import styled from 'styled-components';

export const Nav = styled.nav`
  padding: 16px 0;
  background-color: ${props => props.theme.color.white};
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
`;

export const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;

  @media (min-width: ${props => props.theme.screen.md}) {
    padding: 0 50px;
  }
`;

export const NavListWrapper = styled.div`
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    margin-left: 50px;

    ${({ mobile }) =>
      mobile &&
      `
        flex-direction: column;
        margin-top: 1em;
        margin-left: 0;

        > ${NavItem} {
          text-align: center;
          margin: 0;
          margin-top: 0.75em;
        }
      `};
  }
`;

export const NavItem = styled.li`
  margin: 0 0.75em;
  font-family: ${props => props.theme.font.secondary};
  ${props => props.theme.font_size.small};
  font-weight: ${props => props.theme.font_weight.medium};

  a {
    text-decoration: none;
    color: ${props => props.theme.color.black};
    padding: 0 0 5px;
  }

  &.active {
    a {
      color: ${props => props.theme.color.primary};
      border-bottom: 2px solid ${props => props.theme.color.primary};
    }
  }
`;

export const SocialIcons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  a:hover {
    svg path {
      fill: ${props => props.theme.color.primary};
    }
  }

  svg {
    margin: 0 20px;
    /* width: 24px;
    height: 24px; */

    path {
      ${props => props.theme.transition};
    }
  }

  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-top: 40px;
  }

  ${props =>
    props.hide &&
    `
    display: block;

    @media (max-width: ${props.theme.screen.md}) {
      display: none;
    }
  `}
`;

export const MobileMenu = styled.div`
  width: 100%;
  background: ${props => props.theme.color.white};
`;

export const Mobile = styled.div`
  display: none;

  @media (max-width: ${props => props.theme.screen.md}) {
    display: block;
  }

  ${props =>
    props.hide &&
    `
    display: block;

    @media (max-width: ${props.theme.screen.md}) {
      display: none;
    }
  `}

  ${SocialIcons} {
    margin: 25px 0 5px;
  }
`;

export const LogoNavHolder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media (min-width: ${props => props.theme.screen.md}) {
    width: auto;
  }
`;
