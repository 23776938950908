import React, { Component } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Scrollspy from 'react-scrollspy';
import ExternalLink from '@common/ExternalLink';
import { Link } from 'gatsby';

import { Container, Brand } from '@components/global';
import { ReactComponent as FacebookIcon } from '@static/icons/header/facebook.svg';
import { ReactComponent as TwitterIcon } from '@static/icons/header/twitter.svg';
import { ReactComponent as ZillowIcon } from '@static/icons/header/zillow.svg';
import { ReactComponent as InstagramIcon } from '@static/icons/header/instagram.svg';
import { ReactComponent as MailIcon } from '@static/icons/header/mail.svg';
import { ReactComponent as EnvelopeIcon } from '@static/icons/header/envelope.svg';
import { ReactComponent as PhoneIcon } from '@static/icons/header/phone.svg';

import { ReactComponent as MenuIcon } from '@static/icons/menu.svg';
import {
  Nav,
  NavItem,
  StyledContainer,
  NavListWrapper,
  MobileMenu,
  Mobile,
  LogoNavHolder,
  SocialIcons,
} from './style';

const NAV_ITEMS = ['Listings', 'Reviews', 'Services', 'About', 'Contact'];

class Navbar extends Component {
  // state = {
  //   mobileMenuOpen: false,
  // };
  constructor(props) {
    super(props);
    this.state = {
      mobileMenuOpen: false,
    };
  }

  toggleMobileMenu = () => {
    this.setState((prevState) => ({
      mobileMenuOpen: !prevState.mobileMenuOpen,
    }));
  };

  closeMobileMenu = () => {
    if (this.state.mobileMenuOpen) {
      this.setState({ mobileMenuOpen: false });
    }
  };

  getNavLink = (item) => <Link to={`/#${item.toLowerCase()}`}>{item}</Link>;

  getNavAnchorLink = (item) => (
    <AnchorLink
      href={`#${item.toLowerCase()}`}
      offset={() => 79}
      onClick={this.closeMobileMenu}
    >
      {item}
    </AnchorLink>
  );

  getNavList = ({ mobile = false, isHome }) => (
    <NavListWrapper mobile={mobile}>
      <Scrollspy
        items={NAV_ITEMS.map((item) => item.toLowerCase())}
        currentClassName="active"
        mobile={mobile}
        offset={-100}
      >
        {NAV_ITEMS.map((navItem) => {
          if (!isHome) {
            // If not on homepage Create Nav Links
            return <NavItem key={navItem}>{this.getNavLink(navItem)}</NavItem>;
          }
          return (
            <NavItem key={navItem}>{this.getNavAnchorLink(navItem)}</NavItem>
          );
        })}
      </Scrollspy>
    </NavListWrapper>
  );

  render() {
    const { mobileMenuOpen } = this.state;

    return (
      <Nav {...this.props}>
        <StyledContainer>
          <LogoNavHolder>
            {this.props.isHome ? (
              <Brand href="#home">Shahid Properties</Brand>
            ) : (
              <Brand as="a" href="/">
                Shahid Properties
              </Brand>
            )}
            <Mobile>
              <button
                type="button"
                onClick={this.toggleMobileMenu}
                style={{ color: 'black' }}
              >
                <MenuIcon />
              </button>
            </Mobile>

            <Mobile hide>
              {this.getNavList({ isHome: this.props.isHome })}
            </Mobile>
          </LogoNavHolder>
          <SocialIcons hide>
            <ExternalLink href="https://www.zillow.com/profile/kimshahid/">
              <ZillowIcon />
            </ExternalLink>
            <ExternalLink href="mailto://sales@shahidproperties.com">
              <EnvelopeIcon />
            </ExternalLink>
            <ExternalLink href="tel:843-532-6341">
              <PhoneIcon />
            </ExternalLink>
          </SocialIcons>
        </StyledContainer>
        <Mobile>
          {mobileMenuOpen && (
            <MobileMenu>
              <Container>
                {this.getNavList({ mobile: true, isHome: this.props.isHome })}
              </Container>
              <SocialIcons>
                <ExternalLink href="https://www.zillow.com/profile/kimshahid/">
                  <ZillowIcon />
                </ExternalLink>
                <ExternalLink href="mailto://sales@shahidproperties.com">
                  <EnvelopeIcon />
                </ExternalLink>
                <ExternalLink href="tel:843-532-6341">
                  <PhoneIcon />
                </ExternalLink>
              </SocialIcons>
            </MobileMenu>
          )}
        </Mobile>
      </Nav>
    );
  }
}

export default Navbar;
