import React from 'react';
import BaseBlockContent from '@sanity/block-content-to-react';

const clientConfig = {
  sanity: {
    projectId: process.env.SANITY_TOKEN,
    dataset: 'production',
  },
};

const serializers = {
  types: {},
};

const BlockContent = ({ data }) => (
  <BaseBlockContent
    className="blockContent"
    blocks={data}
    serializers={serializers}
    projectId={clientConfig.sanity.projectId}
    dataset={clientConfig.sanity.dataset}
  />
);

export default BlockContent;
